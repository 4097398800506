import React, { useEffect, useState, useContext, useRef } from 'react'

import { Button, Modal, SectionalAlert } from '../../baseComponents'
import { EditAccountRow } from './EditAccountRow'
import {
  myAccountContext,
  pathContext,
  configurationContext,
} from '../../../contexts/contexts'
import BackupCode from '../BackupCode/BackupCode'

import { useApiClient } from '../../../hooks/useApiClient'
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook'
import './EditAccount.css'
import { useUpdateConfiguration } from '../../../hooks/hooks'

const EditAccount = () => {
  PageTitleHook(
    'USCIS Online Account | Edit your USCIS account'
  )

  const { user, setUser, alert, setAlert, clearAlert, clearPersistentAlert } =
    useContext(myAccountContext)
  const { setUrl, setUserState } = useContext(pathContext)
  const { configuration } = useContext(configurationContext)
  const { appSettings } = configuration

  const [showBackupModal, setShowBackupModal] = useState(false)
  const [showUnlinkModal, setShowUnlinkModal] = useState(false)
  const [showUnlinkConfirmationModal, setShowUnlinkConfirmationModal] =
    useState(false)
  const [loginGovModEnabled, setLoginGovModEnabled] = useState(false)
  const [userSignedIn, setUserSignedIn] = useState(false)

  const sectionalAlertRef = useRef<HTMLDivElement>(null)

  const apiClient = useApiClient()
  const updateConfiguration = useUpdateConfiguration()


  useEffect(() => {
    if (alert.type === 'error') {
      clearAlert()
    }
    // The purpose of this call is to make sure a user is signed in.
    // Ideally in the future this will be taken care of with websockets to avoid the call.
    // A second solution would be to have local storage sync the state of the app so that will sign out a signed out user.

    apiClient.get('/authentication/state').then((res) => {
      // apiClient will redirect when there is no user. Therefore we can assume there is a user.
      setUserSignedIn(true)
      if (alert.message !== '' && sectionalAlertRef.current !== null) {
        sectionalAlertRef.current!.focus()
      }
      try{
        const rp_url = new URL(window.location.href.split('return_to_rp=')[1])
        if(rp_url.hostname.endsWith('uscis.gov') || rp_url.hostname.endsWith('dhs.gov')) sessionStorage.setItem('rp_url', rp_url.href);
      } catch(e) {}
    })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDoneEditing = () => {
    if(sessionStorage.getItem('rp_url')){
      window.location.href = sessionStorage.getItem('rp_url') ?? ""
      return
    }
    clearPersistentAlert()
    setUrl('/dashboard')
  }

  const handleDeleteAccount = () => {
    clearPersistentAlert()
    setUrl('/delete-account/verify-your-account')
  }

  useEffect(() => {
    setUserState("edit")

    setLoginGovModEnabled(
      configuration.appSettings.includes('LoginGovModEnabled')
    )
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (alert.message !== "" && sectionalAlertRef.current) {
      sectionalAlertRef.current!.focus()
    }
  }, [alert])

  const handleModalCancel = () => {
    setShowBackupModal(false)
    setShowUnlinkModal(false)
    setShowUnlinkConfirmationModal(false)
  }

  const getTwoStepVerificationText = () => {
    switch (user.two_factor_method[0]) {
      case 'Email':
        return 'Primary Email'
      case 'Mobile':
        return 'SMS (Mobile Phone)'
      case 'App':
        return 'Authentication App'
      default:
        return user.two_factor_method[0]
    }
  }

  const getMobileText = () => {
    return user.mobile !== null ? user.mobile.replace('+1 ', '') : 'None'
  }

  const getRecoveryEmail = () => {
    return user.recovery_email || 'None'
  }

  const onClickWrapper = (goToUrl: string, refreshUser: boolean) => {
    if (alert.message !== '') {
      clearPersistentAlert()
    }
    setUrl(goToUrl)
  }

  const handleUnlinkOk = () => {
    handleModalCancel()
    apiClient
      .post('/login_dot_gov/update', { lg_email: '', lg_uuid: '' })
      .then((res) => {
        setAlert({ type: 'success', message: res.data.message })
        updateConfiguration()
        setUser({ ...user, login_dot_gov_linked_email: undefined })
      })
      .catch((err) => {
        console.log(err.response)
      })
  }

  if (!userSignedIn) {
    return null
  }

  return (
    <div className="card mx-auto" data-testid="edit-account-container">
      <Modal
        title="Backup Code"
        visible={showBackupModal}
        onCancel={handleModalCancel}
        onOk={handleModalCancel}
        info={false}
        hideCancelButton={true}
        hideOkButton={true}
      >
        <BackupCode modalClose={handleModalCancel} />
      </Modal>
      <Modal
        title="Login.gov Account"
        visible={showUnlinkModal}
        onCancel={handleModalCancel}
        onOk={() => {
          handleModalCancel()
          setShowUnlinkConfirmationModal(true)
        }}
        info={false}
        okButtonText="Unlink"
      >
        <div>
          You can unlink your Login.gov account from USCIS online account. If you do so, you will no longer be able to login to USCIS with your Login.gov credentials.
        </div>
      </Modal>
      <Modal
        title="Are you sure?"
        visible={showUnlinkConfirmationModal}
        onCancel={handleModalCancel}
        onOk={handleUnlinkOk}
        info={false}
        okButtonText="Confirm"
      >
        <div>
          {`Are you sure you want to unlink your Login.gov account 
          ${user.login_dot_gov_linked_email} from your USCIS online account?`}
        </div>
      </Modal>
      <h1 className="text-2xl my-3 font-normal text-dhs_font_gray">
        Edit Account
      </h1>

      {alert.message !== '' && (
        <div
          tabIndex={0}
          id="sectional-alert-2fa"
          ref={sectionalAlertRef}
          className="mb-6"
        >
          <SectionalAlert type={alert.type}>{alert.message}</SectionalAlert>
        </div>
      )}

      <div className="pb-6">
        <EditAccountRow
          testId="primary-email-btn"
          label="Primary Email"
          value={user?.email}
          onClick={() => onClickWrapper('/edit-account/primary-email', true)}
          buttonText="Edit"
        />
        <EditAccountRow
          testId="recovery-email-btn"
          label="Recovery Email"
          value={getRecoveryEmail()}
          onClick={() => onClickWrapper('/edit-account/recovery-email', false)}
          buttonText="Edit"
        />
        <EditAccountRow
          testId="change-password-btn"
          label="Change Password"
          value="************"
          onClick={() => onClickWrapper('/edit-account/change-password', true)}
          buttonText="Edit"
        />
        <EditAccountRow
          testId="two-step-verification-btn"
          label="Two-Step Verification"
          value={getTwoStepVerificationText()}
          onClick={() => onClickWrapper('/edit-account/select-2fa', true)}
          buttonText="Edit"
        />
        <EditAccountRow
          testId="mobile-phone-btn"
          label="Mobile Phone"
          value={getMobileText()}
          onClick={() => onClickWrapper('/edit-account/mobile', true)}
          buttonText="Edit"
        />
        <EditAccountRow
          testId="password-reset-questions-btn"
          label="Security Questions"
          onClick={() =>
            onClickWrapper('/edit-account/set-security-questions', true)
          }
          buttonText="Edit"
        />
        <EditAccountRow
          testId="backup-code-btn"
          label="Backup Code"
          onClick={() => setShowBackupModal(true)}
          buttonText="View/Edit"
        />
        <EditAccountRow
          testId="account-activity-btn"
          label="Account Activity"
          onClick={() => onClickWrapper('/edit-account/account-activity', true)}
          buttonText="View"
        />
        {loginGovModEnabled && user.login_dot_gov_linked_email && (
          <EditAccountRow
            testId="login-gov-unlink-btn"
            label="Login.gov Linked Account"
            value={user.login_dot_gov_linked_email}
            onClick={() => setShowUnlinkModal(true)}
            buttonText="Unlink"
          />
        )}
      </div>
      <div className="button-container">
        <Button
          id="done-editing-btn"
          data-testid="done-editing-btn"
          text="Done Editing My Account"
          type="primary"
          className="sm:w-48"
          onClick={handleDoneEditing}
        />
        {appSettings.includes('UserDeactivationEnabled') && (
          <Button
            id="delete-account-btn"
            data-testid="delete-account-btn"
            text="Delete Your Account"
            type="secondary"
            className="sm:w-48"
            onClick={handleDeleteAccount}
          />
        )}
      </div>
    </div>
  )
}

export default EditAccount
